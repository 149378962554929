import * as React from "react";
import { graphql } from "gatsby";
import { 
    Box,
    Container,
    Heading,
    Flex,
    Text
 } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import { RichText } from "prismic-reactjs";
import StripeDivder from "../components/IceMasks/StripeDivider";
import BackgroundImage from '../components/Backgrounds/BackgroundImage'
import DonateNewsletterBlock from "../components/DonateNewsletterBlock/DonateNewsletterBlock";
import Footer from "../components/Footer/Footer"
import EventsCarousel from "../components/Events/EventsCarousel";
import ProgramCards from "../components/ProgramsCards/ProgramsCards";
import { linkResolver } from "../utils/linkResolver";

const ProgramsPage = ({ data }) => {
  if (!data) return null;
  const document = data.prismicProgramPage.data;
  const backgroundImage = data.allFile.edges[0].node.childImageSharp;
  return (
    <>
    <Seo title="Snow King Sports &amp; Events Center | Programs" />
        <BackgroundImage image={backgroundImage}>
            <Container maxW="container.lg" pt={{base: 24, md: 64}} pb={{base: 16, md: 32}}>
                <Flex w="100%" justifyContent="space-between" flexWrap="wrap">
                    <Box w={{base: "100%", md: "60%"}} pr="4">
                        <Heading as="h1" variant="page-title" textAlign="left" fontSize="clamp(1.25rem, 5vw, 4rem)" mb="4">{document.page_title.text}</Heading>
                        {/* <Text>
                            {document.program_exceprt.text}
                        </Text> */}
                    </Box>
                    <Box w={{base: "100%", md: "40%"}} border="0.5rem solid black">
                        <GatsbyImage image={getImage(document.program_image.localFile)} alt={document.program_image.alt} />
                    </Box>
                </Flex>
            </Container>
        </BackgroundImage>

      <Box backgroundColor="white" >
      <StripeDivder />
      
    <Container maxW="container.lg" my="16">
        {/* <Heading as="h1" variant="page-title" fontSize="clamp(1.25rem, 5vw, 4rem)" mb="4">{document.page_title.text}</Heading> */}
        <RichText render={document.program_details.richText} linkResolver={linkResolver}/>
        
        
    </Container>
    <StripeDivder />
    <Box my="16">
       <Heading as="h2" variant="page-title" fontSize="clamp(2.25rem, 5vw, 4rem)" mb="2" textAlign="center">Other Programs</Heading>
    </Box>
    <ProgramCards />
            <StripeDivder />
        <Heading as="h1" variant="page-title" mb="4" mt="20">Upcoming Events</Heading>
        <Box  py="8">
            <EventsCarousel />
        </Box>
        <BackgroundImage
                    backgroundImage={getImage(backgroundImage)}
                >
        <StripeDivder />
        
        
        <DonateNewsletterBlock
          title="Your Support Makes All The Difference"
          content="Now, of course not, Biff, now, I wouldn't want that to happen. Well, now we gotta sneak this back into my laboratory, we've gotta get you home. Yeah. I can't play. Just say anything, George, say what ever's natural, the first thing that comes to your mind."
          btnText="Donate Now"
          btnLink="/donate"
        />
        <Footer />
      </BackgroundImage>
      </Box>
    </>
  );
};

export const query = graphql`
query ProgramPageQuery($uid: String!)
  {
    allFile(filter: {relativePath: {eq: "Ice.jpg"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 80)
          }
        }
      }
    }
    prismicProgramPage(uid: {eq: $uid}) {
        uid
        data {
          page_title {
            text
          }
          program_details {
            richText
          }
          program_exceprt {
            text
          }
          program_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
`;

export default ProgramsPage;
